export const BLUE = "#639CFF";

export const CARD = [
  { color: "#E6E6E6" },
  { color: "#C3E8BD" },
  { color: "#F9DAD0" },
  { color: "#F2FFD0" },
  { color: "#DAC9FE" },
  { color: "#B3CFFF" },
  { color: "#FFE1A8" },
];
