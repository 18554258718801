import styled from "styled-components";
import { BLUE } from "../Constants/ColorConstants";
import { MONTSERRAT } from "../Constants/FontConstants";
import { ButtonProps, EventProps, TextProps } from "./ComponentProps";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  padding: 0;
  margin: 0;
`;

export const Body = styled.div``;

export const Title = styled.h1`
  font-size: 50px;
  font-family: ${MONTSERRAT};
  font-weight: 600;
`;

export const CenterHorz = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

export const Center = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface SeperatorProps {
  width?: number;
  height?: number;
}

export const Separator = styled.div<SeperatorProps>`
  width: ${(props) => props.width || "0"}px;
  height: ${(props) => props.height || "0"}px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EventCard = styled.div`
  width: ${(props: EventProps) => props.width}px;
  height: ${(props: EventProps) => props.height}px;
  background-color: ${(props: EventProps) => props.color};
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 15px;
`;

export const Text = styled.span`
  font-size: ${(props: TextProps) => props.fontSize}px;
  font-family: ${MONTSERRAT};
  font-weight: ${(props: TextProps) => props.fontStyle};
`;

export const Input = styled.input`
  border: none;
  padding: 10px;
  width: 300px;
  border-radius: 10px;
  font-size: 20px;
  font-family: ${MONTSERRAT};
  font-weight: 500;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
  }
`;

export const Button = styled.button<ButtonProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color};
  color: ${(props) => props.fontColor};
  border-radius: 15px;
  font-family: ${MONTSERRAT};
  font-weight: 600;
  border: none;
  &:hover {
    background-color: ${BLUE};
  }

  @media (max-width: 760px) {
    pointer-events: none;
  }
`;
