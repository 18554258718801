import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Wrapper, Center } from "../../Components/Components";
import { BLUE } from "../../Constants/ColorConstants";
import { MONTSERRAT } from "../../Constants/FontConstants";
import LandingImg from "../../assets/Landing.png";
import { useEffect, useState } from "react";

function LandingSection1() {
  const [titleSize, setTitleSize] = useState<number>(100);
  const [subTitleSize, setSubTitleSize] = useState<number>(40);
  useEffect(() => {
    if (window.innerWidth < 700) {
      setTitleSize(50);
      setSubTitleSize(20);
    } else if (window.innerWidth < 1000) {
      setTitleSize(70);
      setSubTitleSize(30);
    } else {
      setTitleSize(100);
      setSubTitleSize(40);
    }
  }, []);

  window.onresize = (event) => {
    if (window.innerWidth < 700) {
      setTitleSize(50);
      setSubTitleSize(20);
    } else if (window.innerWidth < 1000) {
      setTitleSize(70);
      setSubTitleSize(30);
    } else {
      setTitleSize(100);
      setSubTitleSize(40);
    }
  };
  return (
    <Wrapper>
      <Body>
        <Container
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
          }}
        >
          <Row>
            <Col style={styles.fit} lg={6} md={12} sm={12}>
              <Center>
                <div>
                  <LandingTitle fontSize={titleSize}>Peri</LandingTitle>
                  <LandingSubTitle fontSize={subTitleSize}>
                    Bringing the{" "}
                    <span style={{ color: BLUE, fontWeight: 700 }}>social</span>{" "}
                    back to social media
                  </LandingSubTitle>
                </div>
              </Center>
            </Col>
            <Col style={styles.fit} lg={6} md={12} sm={12}>
              <Center>
                <BodyImage src={LandingImg} />
              </Center>
            </Col>
          </Row>
        </Container>
      </Body>
    </Wrapper>
  );
}

export default LandingSection1;

const styles = {
  fit: {
    display: "flex",
    // border: "red 2px solid",
  },
};

interface TitleProps {
  fontSize: number;
}

export const LandingTitle = styled.h1<TitleProps>`
  font-family: ${MONTSERRAT};
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
`;

export const LandingSubTitle = styled.p<TitleProps>`
  font-family: ${MONTSERRAT};
  font-size: ${(props) => props.fontSize}px;
  font-weight: 300;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 40px;
`;

const BodyImage = styled.img`
  object-fit: contain;
  width: 100%;
  max-width: 800px;
`;
