import "./App.css";
import { Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import PeriEvent from "./pages/PeriEvent/PeriEvent";
import NotFound from "./pages/Error/PageNotFound";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/event/:eventId" element={<PeriEvent />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
