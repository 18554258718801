const MONTHS: { [key: number]: string } = {
  1: "January",
  2: "Feburary",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const convertToDate = (timestamp: number) => {
  return new Date(timestamp);
};

export const prettyDate = (timestamp: number) => {
  const date = convertToDate(timestamp);
  const month = MONTHS[date.getMonth() + 1];
  const day = date.getDate();
  const year = date.getFullYear();
  let period = "AM";

  let hour = date.getHours();
  const minutes = date.getMinutes();

  if (hour > 12) {
    period = "PM";
    hour = hour - 12;
  } else {
    if (hour === 0) {
      hour = 12;
    }
  }

  return `${month} ${day}, ${year} ${hour}:${("0" + minutes).slice(
    -2
  )} ${period}`;
};

export const prettyDateWithoutTime = (timestamp: number) => {
  const date = convertToDate(timestamp);
  const month = MONTHS[date.getMonth() + 1];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};
