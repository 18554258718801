import { Image } from "react-bootstrap";
import styled from "styled-components";
import { UserMinified } from "../../types/types";

interface UserCardProps {
  user: UserMinified;
}

function UserCard(props: UserCardProps) {
  const { username, firstname, lastname, profile_image } = props.user;
  return (
    <Container>
      <Image src={profile_image} style={styles.avatar} roundedCircle />
      <DetailContainer>
        <span>
          {firstname} {lastname}
        </span>
        <span>@{username}</span>
      </DetailContainer>
    </Container>
  );
}

export default UserCard;

const styles = {
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: "lightgrey",
  },
};

const Container = styled.div`
  background-color: white;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  border: 0.1px lightgrey solid;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 25px -20px black;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 20px;
`;
