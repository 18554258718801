import { PeriEvent, UserMinified } from "../types/types";

const IMAGES = [
  "https://i.picsum.photos/id/0/5616/3744.jpg?hmac=3GAAioiQziMGEtLbfrdbcoenXoWAW-zlyEAMkfEdBzQ",
  "https://i.picsum.photos/id/1/5616/3744.jpg?hmac=kKHwwU8s46oNettHKwJ24qOlIAsWN9d2TtsXDoCWWsQ",
  "https://i.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68",
];

export const EventMock: PeriEvent = {
  id: "event-id",
  eventName: "Private Birthday Bash",
  description:
    "A surprise birthday thrown for a user on the platform. This is party is being hosted on the peri app and will be used for testing.",
  eventDate: Date.now(),
  eventType: "PRIVATE",
  eventCategoryType: "IN_PERSON",
  eventPaymentType: "FREE",
  eventColor: "",
  createdBy: {
    id: "userminified-id",
    username: "johndoe",
    firstname: "John",
    lastname: "Doe",
    profile_image: "image-uri",
  },
  attendees: 2,
  eventImages: [...IMAGES],
  invitedUsers: [
    {
      id: "userminified-id",
      username: "robertjackson",
      firstname: "Robert",
      lastname: "Jackson",
      profile_image: "image-uri",
    },
  ],
  accepted: ["robertjackson"],
  declined: [],
  cancelled: false,
};

export const UserMinifiedMock: UserMinified = {
  id: "userminified-id",
  username: "johndoe",
  firstname: "John",
  lastname: "Doe",
  profile_image: "image-uri",
};
