import { Col, Container, Row, Carousel, Image } from "react-bootstrap";
import styled from "styled-components";
import { Button, Separator, Wrapper } from "../../Components/Components";
import { MONTSERRAT } from "../../Constants/FontConstants";
import axios from "axios";
import { useEffect, useState } from "react";
import { PeriEvent } from "../../types/types";
import { EventMock } from "../../db/mock";
import { prettyDate } from "../../Util/DateUtil";
import UserCard from "../../Components/Card/UserCard";
import { ENDPOINT } from "../../Constants/EnvConstants";
import { useParams } from "react-router-dom";

function Event() {
  const { eventId } = useParams();
  const [event, setEvent] = useState<PeriEvent>(EventMock);
  useEffect(() => {
    // window.location.href = "perievent://--/";

    axios
      .get<PeriEvent>(`${ENDPOINT}/api/v1/event/${eventId}`)
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => console.log(err.response));
  }, [eventId]);

  return (
    <Wrapper>
      <Body>
        <Container fluid>
          <Row>
            <Col lg={6} md={12}>
              <EventName>{event?.eventName}</EventName>
              <EventDate>{prettyDate(event?.eventDate)}</EventDate>
              <Title fontSize={20} fontWeight="600">
                About
              </Title>
              <Separator height={20} />
              <EventDescription>{event?.description}</EventDescription>
              <Separator height={30} />
              <Title fontSize={20} fontWeight="600">
                Host
              </Title>
              <Separator height={10} />
              <UserCard user={event.createdBy} />
              <Separator height={50} />
              <a href={`perievent://event/${event.id}/${event.eventType}`}>
                <Button
                  width={150}
                  height={50}
                  fontColor="white"
                  color="tomato"
                >
                  Register
                </Button>
              </a>
              <Separator height={50} />
            </Col>
            <Col lg={6} md={12}>
              <Carousel>
                {event?.eventImages?.map((image, index) => (
                  <Carousel.Item key={index}>
                    <Image width={"100%"} src={image} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Body>
    </Wrapper>
  );
}

export default Event;

const EventName = styled.h1`
  font-family: ${MONTSERRAT};
  font-weight: 700;
  margin-bottom: 5%;
`;

const EventDescription = styled.h4`
  font-family: ${MONTSERRAT};
  font-weight: 300;
  font-size: 20px;
  margin-right: 30px;
`;

const EventDate = styled.p`
  font-family: ${MONTSERRAT};
  font-weight: 600;
  font-size: 20px;
  color: grey;
  padding-bottom: 20px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 5%;

  @media (max-width: 760px) {
    padding-top: 100px;
  }
`;

interface TitleProps {
  fontSize: number;
  fontWeight: "300" | "500" | "600" | "700";
}

const Title = styled.span<TitleProps>`
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(propss) => propss.fontWeight};
`;
