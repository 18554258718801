import LandingSection1 from "./Section1";

function Landing() {
  return (
    <>
      <LandingSection1 />
    </>
  );
}

export default Landing;
