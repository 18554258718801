import { Wrapper } from "../../Components/Components";
import Lottie from "lottie-react-web";
import NotFoundAnimation from "../../assets/Lottie/404.json";
import styled from "styled-components";

function NotFound() {
  return (
    <Wrapper>
      <Body>
        <Lottie
          style={{
            width: "100%",
            maxWidth: 800,
          }}
          options={{
            animationData: NotFoundAnimation,
          }}
        />
      </Body>
    </Wrapper>
  );
}

export default NotFound;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
